const data = [
  {
    src:
      "https://archdesignsstudio.com/assets/projects/pravaramedicaltrust/pic7.png",
    text: "Medical College",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/pravaramedicaltrust/pic6.png",
    text: "Medical College",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/pravaramedicaltrust/pic8.png",
    text: "Medical College",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/pravaramedicaltrust/pic9.png",
    text: "Lecture Hall and Theatre Complex",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/pravaramedicaltrust/pic10.png",
    text: "Lecture Hall and Theatre Complex",
  },

  {
    src:
      "https://archdesignsstudio.com/assets/projects/pravaramedicaltrust/pic1.png",
    text: "Museum Cum Cultural Centre",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/pravaramedicaltrust/pic2.png",
    text: "Museum Cum Cultural Centre",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/pravaramedicaltrust/pic3.png",
    text: "Museum Cum Cultural Centre",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/pravaramedicaltrust/pic4.png",
    text: "Museum Cum Cultural Centre",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/pravaramedicaltrust/pic5.png",
    text: "Museum Cum Cultural Centre",
  },
];

export default data;
